/*--------------------------------------------------
    ## Footer Styling (Responsive)
---------------------------------------------------*/
.footer-area {
  background-color: #f66711;
  color: #fff;
  text-align: center;
  padding-bottom: 30px;

  /* Top Section */
  .footer-top {
    padding: 30px 20px;

    .footer-top-content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      max-width: 1100px;
      margin: auto;
      text-align: left;

      .footer-title h3 {
        font-size: 24px;
        font-weight: bold;
        margin-bottom: 5px;
        color: #fff;
      }

      .footer-title p {
        font-size: 14px;
        color: #fff;
      }

      .subscribe-box {
        display: flex;
        align-items: center;
        background: #fff;
        border-radius: 10px;
        overflow: hidden;
        

        input {
          border: none;
          padding: 12px;
          font-size: 14px;
          width: 250px;
          outline: none;
        }

        input::placeholder
        {
          color: #f66711;
        }

        button {
          background-color: #f66711;
          color: #fff;
          border: none;
          padding: 12px;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 8px;
          border-radius: 4px;
        }

        button svg {
          font-size: 16px;
        }
      }
    }
  }

  /* Middle Section */
  .footer-middle {
    display: flex;
    justify-content: space-between;
    max-width: 1100px;
    margin: auto;
    padding: 30px 20px;
    border-top: 1px solid rgba(255, 255, 255, 0.2);

    .footer-column {
      flex: 1;
      text-align: left;
      min-width: 150px;

      h4 {
        font-size: 18px;
        margin-bottom: 10px;
        font-weight: bold;
        
      }

      ul {
        list-style: none;
        padding: 0;

        li {
          margin-bottom: 5px;
        }

        a {
          text-decoration: none;
          color: #fff;
          font-size: 14px;
        }
      }

      p {
        font-size: 14px;
        margin-bottom: 5px;
        color: #fff;
      }

      .social-icons {
        display: flex;
        gap: 10px;

        a {
          color: #fff;
          font-size: 16px;
        }
      }
    }
  }

  /* Bottom Section */
  .footer-bottom {
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    padding: 15px 0;
    font-size: 14px;
    
  }

  .footer-bottom p
  {
    color: #fff;
  }
}

/*--------------------------------------------------
    ## Responsive Adjustments
---------------------------------------------------*/

/* Tablet View */
@media (max-width: 1024px) {
  .footer-top .footer-top-content {
    flex-direction: column;
    text-align: center;
  }

  .subscribe-box {
    margin-top: 15px;
  }

  .footer-middle {
    flex-wrap: wrap;
    justify-content: space-around;
  }

  .footer-column {
    width: 50%;
    margin-bottom: 20px;
    text-align: center;
  }
}

/* Mobile View */
@media (max-width: 768px) {
  .footer-top .footer-top-content {
    flex-direction: column;
    align-items: center;
  }

  .subscribe-box {
    width: 100%;
    display: flex;
    justify-content: center;

    input {
      width: 70%;
    }
  }

  .footer-middle {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .footer-column {
    width: 100%;
    margin-bottom: 20px;
  }
}
